import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ShippingInformation from '../common/ShippingInformation';
import { shippingInfoValidator, shippingInfoValidatorWithTrackingScreenshot } from '../claimValidators';
import useStepValidation from './useStepValidation';
import ClaimStepClosableMessage from './ClaimStepClosableMessage';
import Locale from '../../../Translate/Locale';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import { useClaimCarrierSelectOptions } from './useClaimCarrierSelectOptions';
import { isClaimWithNoFHSWarning } from '../claimsHelpers';

function ShippingInfoStep(props) {
  const {
    as: Container,
    showDeliveryInfo,
    claim,
    carriers,
    onChange,
    onNext,
    isClaimsAutomated,
    isManual,
    ...restProps
  } = props;

  const { isNon7sCarrier, carrierOptions, carrierCountryOptions } = useClaimCarrierSelectOptions({
    carrierCode: claim.carrierCode,
    carrierCountryCode: claim.carrierCountryCode,
    carriers,
  });

  const isMissingFHS = isClaimWithNoFHSWarning(claim.claimableWarnings, claim.type);

  const { isTrackingScreenshotRequired, noTrackingScreenshotRequiredMessage } = getScreenshotRequiredState({
    isClaimsAutomated,
    isNon7sCarrier,
    isMissingFHS,
  });

  const validator = isTrackingScreenshotRequired ? shippingInfoValidatorWithTrackingScreenshot : shippingInfoValidator;
  const [errors, onClickNext] = useStepValidation(claim, onNext, validator);
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <Container {...restProps} onNext={onClickNext} isDisabled={isDisabled}>
      {(isManual || isMissingFHS) && !claim.outsideOfDeadlineReason && (
        <ClaimStepClosableMessage intent="success">
          <Locale>CLAIMS_WITHIN_DEADLINE_MESSAGE</Locale>
        </ClaimStepClosableMessage>
      )}
      <SidePanelContentHeader>Shipping information</SidePanelContentHeader>
      <ShippingInformation
        claim={claim}
        carrierOptions={carrierOptions}
        carrierCountryOptions={carrierCountryOptions}
        showDeliveryInfo={showDeliveryInfo}
        onChange={onChange}
        errorsWithTranslation={errors}
        onBusy={setIsDisabled}
        noTrackingScreenshotRequiredMessage={noTrackingScreenshotRequiredMessage}
        isManual={isManual}
      />
    </Container>
  );
}

function getScreenshotRequiredState({ isClaimsAutomated, isNon7sCarrier, isMissingFHS }) {
  if (isMissingFHS && !isNon7sCarrier) {
    return { isTrackingScreenshotRequired: true };
  }

  if (!isClaimsAutomated && !isNon7sCarrier) {
    return { isTrackingScreenshotRequired: true };
  }

  return {
    isTrackingScreenshotRequired: false,
    noTrackingScreenshotRequiredMessage: isNon7sCarrier
      ? 'CLAIMS_NO_TRACKING_SCREENSHOT_FOR_N7S_SHIPMENT'
      : 'CLAIMS_AUTOMATIC_SCREENSHOT_CONFIRMATION',
  };
}

ShippingInfoStep.propTypes = {
  as: PropTypes.elementType.isRequired,
  showDeliveryInfo: PropTypes.bool,
  claim: PropTypes.object,
  carriers: PropTypes.array,
  onChange: PropTypes.func,
  isClaimsAutomated: PropTypes.bool,
  isManual: PropTypes.bool,
};

export default React.memo(ShippingInfoStep);
