import { Machine as machine } from 'xstate';
import {
  isClaimOutsideOfDeadline,
  isWarehouseDeliveryDateRequired,
  isClaimWithMailboxDeliveryWarning,
  isClaimWithMissingFHS,
} from './claimsHelpers';

export default machine({
  key: 'Claims',
  strict: true,
  initial: 'choose-claim-type',
  states: {
    'choose-claim-type': {
      on: {
        SELECT_DAMAGE: 'choose-damage-type',
        SELECT_INVESTIGATION: 'investigation',
        SELECT_MISSING_ITEM: 'missing-item',
      },
    },
    'choose-damage-type': {
      on: {
        SELECT_PARTIAL_DAMAGE: 'partial-damage',
        SELECT_WHOLE_DAMAGE: 'whole-damage',
      },
    },
    investigation: {
      initial: 'initial-state-choices',
      states: {
        'initial-state-choices': {
          always: [
            {
              target: 'investigation-delivered-to-mailbox',
              cond: isClaimWithMailboxDeliveryWarning,
            },
            {
              target: 'deadline-shipping-info',
              cond: isClaimWithMissingFHS,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'shipping-info',
            },
          ],
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'investigation-delivered-to-mailbox': {
          on: {
            NEXT: [
              {
                target: 'outside-of-deadline',
                cond: isClaimOutsideOfDeadline,
              },
              {
                target: 'shipping-info',
              },
            ],
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: [
              {
                target: 'investigation-delivery-info',
                cond: (claim) => claim.isDelivered,
              },
              {
                target: 'invoice-info',
              },
            ],
          },
        },
        'investigation-delivery-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'partial-damage': {
      initial: 'initial-state-choices',
      states: {
        'initial-state-choices': {
          always: [
            {
              target: 'warehouse-delivery-confirmation-info',
              cond: isWarehouseDeliveryDateRequired,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'shipping-info',
            },
          ],
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'partial-damage-info',
          },
        },
        'partial-damage-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'whole-damage': {
      initial: 'initial-state-choices',
      states: {
        'initial-state-choices': {
          always: [
            {
              target: 'warehouse-delivery-confirmation-info',
              cond: isWarehouseDeliveryDateRequired,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'shipping-info',
            },
          ],
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'whole-damage-info',
          },
        },
        'whole-damage-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'missing-item': {
      initial: 'initial-state-choices',
      states: {
        'initial-state-choices': {
          always: [
            {
              target: 'warehouse-delivery-confirmation-info',
              cond: isWarehouseDeliveryDateRequired,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'shipping-info',
            },
          ],
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'missing-item-info',
          },
        },
        'missing-item-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
  },
});
